.btn-custom-white {
    border: 1px solid #dddddd !important;
    border-radius: 4px !important;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 20px;
    background-color: white;
    min-width: 40px;
}

.btn-custom-link {
    @extend .btn-outline-primary;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    min-width: 0px;
    font-size: 10px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1.07px;
    border: 1px solid #DDDDDD;
}

.text-custom-date {
    color: #CCCCCC;
}

@media (min-width: 576px) {
   .modal-alert {
       width: 520px;
   }
}

.modal-alert {
    .btn-danger {
        background-color: red !important;
    }
}

.btn-custom-icon {
    background-color: transparent;
    border:none;
    padding: 5px;
    padding-top: 6px;
    padding-left: 0px;
}

.global-markup-tooltip {
    font-size: 11px;
}

.product-list-alert {
    display: flex;
    justify-content: flex-end;
    padding: 0;

    .close {
        padding: 0.5rem 1.25rem;
    }
}

.table-disable {
    td {
        background-color:rgba(247, 247, 247, 0.5);
    }
    th {
        background-color:rgba(247, 247, 247, 0.5);
    }
}
.text-content {
    font-size: 14px;
}

.text-custom-green {
    color: #6BAD6D;
}
.text-custom-gold {
    color: rgb(222,162,40);
}

.text-custom-red {
    color: #E02020;
}

input {
    color: black !important;
}


.table {
    tr{
        &:first-child {
            td {
                border-top: none;
            }
        }
    }
}

.collection-item {
    padding-top: 25px;
    div {
        color: #828282;
        padding: 4px 0px;
        justify-content: space-between !important;
        font-size: 14px;
        display: flex;
        border-bottom: 1px solid #828282;
        &:last-child {
            border-bottom: none;
        }
    }
}

select {
    padding-right: 16px;
    width: auto;
    height: 22px;
    margin-right: 5px;
    border: 1px solid #ff9900;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    }

select:first-child, select#f {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent url("../images/arrow_down.svg") no-repeat 97% center;
    background-size: 15px;
    left: -5px;
}

//Order page
.detail-left {
    width: 250px;
}
.order-detail {
    .card-body{
        .detail-left {
            h5 {
                font-size: 18px;
                letter-spacing: 0px;
                margin-bottom: 5px;
                color:black;
            }
            h6 {
                font-size: 12px;
                color:black;
                margin-bottom: 6px;
                padding-top: 2px;
                font-weight: 700;
                line-height: 26.25px;
            }
            p {
                font-size: 12px;
                letter-spacing: 0px;
                margin: 0px;
                line-height: 16px;
                font-weight: 400;
            }
        }
        .detail-right {
            padding: 17px 25px;
            border-radius: 4px;
            p{
                font-size: 14px;
                margin: 0px;
                line-height: 19px;
                letter-spacing: 0px;
                a {
                    font-weight: 500;
                }
            }
            table {
                tr {
                    th {
                        border-top: none ;
                        border-bottom: 0.75px solid rgb(221, 221, 211);
                    }
                    td {
                        vertical-align: top;
                        padding: 16px;
                        border-bottom: 0.75px solid rgb(221, 221, 211);
                        &:last-child {
                            text-align: right;
                         }
                    }
                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        .customer-right {
            div.info-box {
                padding-top: 10px;
                padding-bottom: 10px;
                a {
                    font-size: 14px;
                    font: 500;
                }
                .box-price {
                    font-size: 14px;
                }
                flex: 1;
            }
            table {
                tr {
                    th {
                        border-top: none ;
                        border-bottom: 0.75px solid rgb(221, 221, 211);
                    }
                    td {
                        vertical-align: top;
                        padding: 16px;
                        border-bottom: 1px solid white;
                    }
                }
            }
        }
    }

}
// Register form
.subdomain-group {
    position: relative;
}
.subdomain-placeholder {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.status-banner {
    height: 45px;
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0 10px;
    text-align: center;
}
.status-banner.status-active {
    background: #5cb85c;
}
.status-banner.status-error {
    background: #d9534f;
}
@media(max-width: 1024px) {
    .subdomain-placeholder {
        top: 10px;
        font-size: 12px !important;
        font-weight: 400 !important;
    }
}

// Password Reset



.reset-wrapper {
    width: 500px;
    background: #FFFFFF;
    margin: 20vh auto;
    padding: 40px 80px 80px;
    border-radius: 5px;
}
.reset-wrapper .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.reset-wrapper .auth-form .form-group {
    width: 100%;
}
.reset-wrapper .auth-form .form-button {
    margin-top: 10px;
}
.reset-upper-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.reset-upper-text {
    margin-top: 20px;
    color: #000000;
}
.reset-image-logo {
    max-width: 35%;
}

@media (max-width: 540px) {
    .reset-wrapper {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 80px;
    }
}

.typography-container {
    width: 100%;
    .dropdown-toggle {
        width: 100%;
    }
}

.handle-input {
    .form-field {
        .form-group {
            margin-bottom: 0;
        }
    }
}


.category-products-container {
    table {
        position: relative;

        .products-loader {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
        }
    }
}

table.scrollable {
    thead {
        display:table;
        width:100%;
        table-layout:fixed;
    }

    tbody {
        display:block;
        max-height:400px;
        overflow-y:auto;
        overflow-x: hidden;
        tr {
            display:table;
            width:100%;
            table-layout:fixed;
        }
    }
}

table.draggable {
    tbody {
        tr:hover {
            cursor: grab;
        }
        tr:active {
            cursor: grabbing;
        }
    }
}
.global-drag-line {
    border-color: #007936 !important;
}